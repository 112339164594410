@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins_bold;
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Montserrat_bold;
  src: url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf);
}

::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(0.5);
}

html {
  scroll-behavior: smooth;
}

.select_tab {
  display: block;
  width: 100%;
  padding: 10px;
  outline: none;
  color: #716c80;
}

.select_tab:invalid {
  color: #716c8080;
}

.ourwork-card-text {
  cursor: pointer;
}

.brad_cumb .breadcrumb {
  background-color: transparent;
  padding-left: 50px;
}

.brad_cumb .breadcrumb-item.active {
  color: #1d83ff;
}

.brad_cumb .breadcrumb-item a {
  color: grey;
}

.select_tab1 {
  display: block;
  width: 100%;
  padding: 10px;
  outline: none;
  color: #495057;
}

.select_tab1:invalid {
  color: #716c8080;
}

.date_time:invalid,
input[type="date"]:invalid {
  color: rgba(113, 108, 128, 0.5);
}

.fixed_value {
  position: relative;
}

.fixed_value .unit {
  position: absolute;
  display: block;
  left: 5%;
  z-index: 9;
  bottom: 10%;
}

.fixed_value .unit1 {
  position: absolute;
  display: block;
  left: 13px;
  z-index: 9;
  bottom: 15%;
  color: #716c8080;
}

.fixed_value .doller_price {
  padding-left: 22px;
}

.widthSet {
  flex: 0 0 17% !important;
}

.search-button {
  position: absolute;
  right: 1rem;
  border-radius: 41px !important;
  width: 124px;
  font-family: Poppins;
}

.search-button-small {
  position: absolute;
  right: 0.1rem;
  border-radius: 41px !important;
  font-family: Poppins;
}

.lable_color,
.lable_color label {
  color: #1d83ff;
}

.align_right {
  text-align: right;
}

.inspection_font {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #818181;
}

.align_center {
  text-align: center;
}

.navigation_button {
  background-color: #fff !important;
  color: #1d83ff !important;
  padding: 12px 0px !important;
  width: 29.6% !important;
  margin: 0 10px;
}

.navigation_button:hover {
  background-color: #1d83ff !important;
  color: #fff !important;
}

.next_button {
  color: #fff !important;
  background-color: #1d83ff !important;
  padding: 12px 50px !important;
  margin: 0 10px;
}

.next_button:hover {
  background-color: #fff !important;
  color: #1d83ff !important;
}

.next-btn-block {
  padding: 80px 0 30px;
}

.navigations {
  width: 100%;
  float: right;
  display: block !important;
  text-align: right;
}

.home-section-title {
  font-family: Poppins_bold;
  font-weight: bolder;
  font-size: 49px;
  line-height: 70px;
  color: #2e2e2e;
  line-height: 132.7%;
  width: 100%;
}

.aboutus-root .col {
  width: 20%;
}

.home-section-title-1 {
  font-family: Poppins_bold;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #1d83ff;
  letter-spacing: 3px;
  white-space: nowrap;
}

.footer-bottom-line {
  background-color: #1d83ff;
  height: 72px;
}

.app-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
}

.app-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143.6%;
  color: #818181;
  z-index: 0;
  position: relative;
}

.rbc-toolbar button,
.rbc-month-view,
.rbc-month-view {
  border: 0.3px solid rgba(58, 122, 248, 0.5) !important;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.rbc-event-content {
  background-color: rgb(58, 122, 248) !important;
}

.rbc-off-range-bg {
  background-color: rgba(58, 121, 248, 0.089) !important;
}

.rbc-active {
  background-color: rgb(58, 122, 248) !important;
  color: #fff !important;
}

.hero-place-autocomplete input.contact-form-input.starta_home_search.form-control.pac-target-input {
  border: 0px !important;
  background: #ffffff;
  border-radius: 40px !important;
  font-family: Poppins;
  font-size: 15px !important;
  height: 48px !important;
}

input[type="text"],
select,
textarea {
  -webkit-appearance: none;
}

.box-shaddow {
  -moz-box-shadow: 10px 10px 5px #888 !important;
  -webkit-box-shadow: 5px 5px 15px rgb(67 127 236 / 10%) !important;
  box-shadow: 5px 5px 15px rgb(67 127 236 / 10%) !important;
}

.rbc-toolbar button:hover {
  background-color: rgb(58, 122, 248) !important;
  color: #fff !important;
}

.rbc-toolbar-label {
  text-align: right !important;
  font-weight: 800 !important;
}

.starta_search {
  padding: 30px !important;
  width: 80% !important;
  margin: 0 auto !important;
}

.strata_search {
  right: 15% !important;
}

.search_button {
  position: absolute;
  width: 15% !important;
  height: 55px !important;
  border-radius: 40px !important;
  right: 26%;
  margin: 3px 0;
  z-index: 0;
}

span.err {
  color: red;
  font-size: 13px;
  padding-left: 16px;
}

.starta_home_search {
  padding: 30px 40px 30px 19px !important;
  width: 85% !important;
}

select#select_strata_report {
  position: absolute;
  top: 3%;
  border: none;
  right: 40%;
  outline: none;
  color: grey;
  cursor: pointer;
  background: #fff;
  width: 57%;
  padding: 15px 10px;
}

p.show_address {
  font-size: 18px;
  padding: 0px 21px;
  font-weight: 700;
  color: #757575;
}

#select_strata_report option {
  color: #007bff;
}

.btn-outline-primary:disabled {
  color: #007bff !important;
  background-color: transparent;
}

#select_strata_report .hide_text {
  color: #595959;
}

#select_strata_report:invalid {
  color: rgba(113, 108, 128, 0.5019607843137255);
}

.search_home_button {
  position: absolute;
  width: 22% !important;
  height: 55px !important;
  border-radius: 40px !important;
  right: 16%;
  bottom: 0%;
  margin: 3px 0;
  z-index: 0;
}

.thank-you {
  padding: 9px 0px;
  color: #1d83ff;
}

.app-link {
  font-family: Poppins_bold;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 143.6%;
  color: #1d83ff;
}

.app-link:hover {
  cursor: pointer;
}

.app-button {
  border-radius: 2rem !important;
}

.table td,
.table th {
  padding: 1rem;
  vertical-align: middle;
  border-top: 0px !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px !important;
}

.portal-choose-avatar1 {
  margin: 20px 50px 0px 50px;
}

.order-card-width {
  width: 410px;
}

.portal-tabs {
  padding: 0 15px;
}

body .portal-tabs .nav-link {
  padding: 10px 26px;
  line-height: 24px;
}

.conversion-cart {
  padding: 10px;
}

.conversion-cart .cart-text {
  font-size: 16px;
  line-height: 19px;
}

body .cart-conversion-dropdown {
  color: #1d83ff;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 28px !important;
  cursor: pointer;
}

.conver-tracker-cart {
  padding: 20px;
}

.set-notific-block {
  padding: 25px;
}

.portal-card-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #444444;
  margin: 0;
  font-family: "Roboto";
}

.portal-card-text {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 17px;
  color: #444444;
}

.portal-card {
  padding: 34px 25px;
}

.notifica-title {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  color: #1d83ff;
  font-weight: 400;
}

.notific-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #818181;
}

.set-notific {
  padding: 13px 25px;
}

/* width */
.strata-scroll::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.strata-scroll::-webkit-scrollbar-track {
  background: #f1f1f100;
}

.strata-scroll:hover::-webkit-scrollbar-track {
  background: #a0a0a08a;
}

/* Handle */
.strata-scroll::-webkit-scrollbar-thumb {
  background: rgba(255, 0, 0, 0);
}

.strata-scroll:hover::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163) !important;
}

/* Handle on hover */
.strata-scroll:hover::-webkit-scrollbar-thumb:hover {
  background: rgb(129, 129, 129);
}

.aboutus-card-icon-border-2 img {
  filter: brightness(0) invert(1);
  width: 27px;
  object-fit: contain;
}

.portal-detail-tabs {
  justify-content: flex-start;
  border: 0px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: #1d83ff;
}

body .portal-detail-tabs .nav-link {
  padding: 0px 54px 12px;
}

.portal-details-header {
  border-radius: 5px;
}

.portal-details-header th {
  color: #444444;
  font-size: 16px;
  line-height: 18px;
  font-family: "Roboto";
}

.MuiTab-root {
  min-width: unset !important;
}

body .portal-detail-row td {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #818181;
  padding: 20px;
}

.portal-detail-row tr {
  border-bottom: 0.3px solid rgba(29, 131, 255, 0.5);
}

.protal-details-table {
  padding: 20px;
}

.profile-portal-block {
  margin: 48px 16px 160px;
}

.set-order-block {
  margin-bottom: 160px;
}

.portal-account-details .form-label {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1d83ff;
  padding: 0;
}

.portal-account-details .form-control {
  font-size: 14px;
  line-height: 18px;
  padding: 10px 22px;
  border-radius: 7px;
  border: 0.3px solid rgba(29, 131, 255, 0.5);
  height: auto;
}

.portal-account-details .form-group {
  margin: 10px 0;
}

.portal-account-details {
  margin-top: 30px;
}

.portal-details-btn {
  margin: 76px 0 0;
}

.portal-details-btn button {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 29px;
  width: auto;
}

h3.report_title {
  text-align: center;
  font-weight: 700;
  padding-bottom: 25px;
}

p.price-text {
  font-size: 26px;
  color: #1d83ff;
  font-weight: 700;
}

.covid_info {
  color: red !important;
}

.portal-contact-form .form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #1d83ff;
}

.portal-contact-form .form-control {
  border-radius: 25px;
  padding: 13px 26px;
  font-size: 16px !important;
  line-height: 21px;
}

.portal-contact-form .portal-account-detail-button {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  padding: 12px 70px;
  width: auto;
  margin: 45px 0 0 !important;
}

.portal-contact-form {
  padding: 27px 14px 70px;
}

.portal-table-content .card-header:hover {
  background: rgba(29, 131, 255, 0.1);
  cursor: pointer;
}

.upload-report-block {
  background: #fff;
  padding: 28px;
  border: 0.5px solid rgba(58, 122, 248, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
}

.upload-report-block .upload-block-title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1d83ff;
}

body .upload-report-block .upload-report-title {
  font-size: 16px;
  line-height: 19px;
}

.upload-report-block .form-label {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #595959;
  padding: 6px 0;
}

.upload-report-block .form-control {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(58, 122, 248, 0.5);
  border-radius: 10px;
}

.upload-report-block .portal-image-upload {
  border: none;
}

.upload-report-block .upload-report-button button {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background: #3a7af8;
  border: 0.5px solid rgba(58, 122, 248, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 9px 20px;
  transition: all 0.3s;
}

.upload-report-block .upload-report-button button:hover {
  color: #3a7af8;
  background: #ffffff;
}

.recent-upload-block {
  padding: 28px;
}

.upload-block-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #1d83ff;
}

.recent-upload-block .portal-table-header {
  padding: 0 !important;
}

body .order-process-row .card-header .portal-tale-text {
  padding: 9px 30px !important;
}

.portal-more-collapse .card-body {
  padding: 34px 48px;
  background: #fff;
}

.portal-more-collapse .portal-property-title {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
  padding-bottom: 22px;
}

.portal-more-collapse .portal-tale-text {
  padding: 5px 0;
}

.portal-more-collapse .portal-account-detail-button {
  padding: 8px 34px !important;
}

.portal-more-collapse {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.avatarMainImage {
  width: 100%;
  max-height: 100%;
  border-radius: 100px;
}

.login-profile-dropdown {
  border-radius: 100px;
  overflow: hidden;
}

.comingsoon h3 {
  font-size: 54px;
  font-weight: bold;
  color: #2e2e2e;
  text-align: center;
  padding: 74px 0 0 0;
}

.comingsoon p {
  font-size: 20px;
  color: #818181;
  text-align: center;
  padding: 10px 0 0 0;
}

h4.commingContact {
  font-size: 30px;
  color: #2e2e2e;
  font-weight: bold;
  padding-bottom: 20px;
}

.footer-bottom-line p {
  font-size: 18px;
  color: #fff;
  padding: 22px 0;
  margin: 0;
}

.footer-bottom-line p a {
  color: #fff;
  text-decoration: none;
}

/* css starts date 26/04/2023 */
.border-box {
  border: 1px solid #81818166;
  height: 192px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 13px;
}

.border-box label {
  color: #000;
  font-size: 15px;
  font-family: "Poppins_bold";
  /* text-transform: uppercase; */
}

.propert-img {
  width: 53px;
  margin: 12px 0 0 0;
}

/* .inner-box {
  padding: 11px;
  border: 1px solid #81818166;
} */
.modal-dialog .agree-checkbox {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 22px;
  color: #444444 !important;
}

.inner-box label {
  color: #000;
  font-family: "Poppins";
  /* text-transform: capitalize; */
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 13px;
  padding: 12px;
  display: block;
  border: 1px solid #81818166;
  cursor: pointer;
  border-radius: 9px;
}

.set-build-form .form-check-label,
.set-valuation-form .form-check-label {
  color: #756c84 !important;
}

/* .border-box .lable_color.form-check input {
  width: 100%;
  height: 192px;
  position: absolute;
  top: -90px;
  opacity: 0;
} */
.border-box input[type="radio"] {
  display: none;
}

.border-box input[type="radio"]:checked+.border-box {
  background-color: #1d83ff;
  border: none;
  color: #fff;
}

/* .inner-box .lable_color.form-check input {
  width: 100%;
  position: absolute;
  opacity: 0;
} */
.inner-box input[type="radio"] {
  display: none;
}

.inner-box input[type="radio"]:checked+label {
  background-color: #1d83ff;
  border: none;
  color: #fff;

}

.inner-box .lable_color.form-check {
  padding-left: 8px;
  text-align: center !important;
}

.month-txt {
  font-size: 28px;
  text-align: center;
  display: block;
  font-weight: 800;
  color: #1d83ff;
}

.months-section label {
  text-align: center;
  display: block;
}

.selected-opt {
  border: 2px solid #1d83ff;
}

.date-img {
  width: 13%;
  margin: 0 auto !important;
  display: block;
  text-align: center;
}

.company-txt {
  font-size: 15px !important;
}

.insight-btn {
  text-align: center !important;
  margin: 0 auto;
  display: block !important;
}

.build-form-title {
  font-size: 18px !important;
  line-height: 36px !important;
  color: #1d83ff !important;
  font-family: 'Poppins' !important;
  font-weight: normal !important;
  margin-bottom: 10px !important;
}

.modal-dialog {
  max-width: 600px !important;
}

.property-whispers-logo {
  width: 90%;
}

.border-box .form-check {
  padding-left: 0 !important;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.hero-head-title-1 h1 {
  color: #2E2E2E;
  font-size: 64px;
  font-style: normal;
  line-height: 75.41px;
  font-family: Montserrat;
}

.position-absolute {
  z-index: -1;
}

.testimonial-content.card-body .testmonial-quate {
  width: 100%;
  max-width: 60px;
  margin-right: 15px;
}

.testimonial-content.card-body .set_testmonial_text {
  padding: 0;
  font-size: 15px;
  line-height: 27px;
  margin-top: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.testimonial-content.card-body h6 {
  font-size: 19px;
  margin-top: 20px;
}

.testimonial-box.card {
  padding: 30px;

}

.author-desc {
  font-size: 14px !important;
}

.star-time {
  display: flex;
  align-content: center;
}

.testimonial-top {
  display: flex;
  align-content: center;
}

.rating-area.react-stars span {
  font-size: 17px !important;
}

.testmonial-text.testimonial-pro-name {
  color: #000;
  font-size: 19px;
}

.testimonial-content.card-body {
  padding: 0 !important;
}

/* css ends date 26/04/2023 */
@media screen and (max-width: 1800px) {
  .search_button {
    right: 23%;
  }
}

@media screen and (max-width: 1600px) {
  .search_button {
    right: 19.6%;
  }
}

@media screen and (max-width: 1200px) {
  .aboutus-root .col {
    width: 25% !important;
  }
}

@media screen and (max-width: 991px) {
  .aboutus-root .col {
    width: 33.33% !important;
  }

  .aboutus-card-title.card-title.h5 {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .search_button {
    right: 15%;
    padding: 8px 0 !important;
    height: auto !important;
  }

  .comingform.row {
    display: block;
  }

  .comingform.row .col-md-6 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .comingfooter {
    height: 100% !important;
  }

  .comingfooter .col-md-6 {
    text-align: left !important;
  }

  */.comingsoon h3 {
    font-size: 34px;
    padding: 22px 0 0 0;
  }

  .comingsoon p {
    font-size: 13px;
  }

  h4.commingContact {
    font-size: 22px;
  }

  .footer-bottom-line p {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .comingimage {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .next-btn-block {
    padding: 40px 0 15px !important;
  }

  .recent-upload-block {
    padding: 10px;
  }

  .portal-contact-form {
    padding: 0px;
  }

  .portal-details-btn button {
    margin: 5px 0;
  }

  .upload-img-block {
    margin-bottom: 10px;
  }

  .portal-details-btn {
    margin: 40px 0 0;
  }

  .portal-search-button {
    right: 15px !important;
  }

  .set-order-block {
    margin-bottom: 100px;
  }

  .profile-portal-block {
    margin: 40px 16px 100px;
  }

  .protal-details-table {
    padding: 10px;
  }

  body .portal-detail-tabs .nav-link {
    padding: 0px 26px 6px;
  }

  body .portal-detail-row td {
    padding: 8px;
  }

  .portal-card,
  .set-notific-block {
    padding: 15px 12px;
    margin-bottom: 5px;
  }

  .conver-tracker-cart {
    padding: 0px;
  }

  .portal-tabs {
    padding: 0;
  }

  .home-section-title {
    font-family: Poppins_bold;
    font-weight: bolder;
    font-size: 25px;
    color: #2e2e2e;
  }

  .home-section-title-1 {
    font-size: 17px;
    line-height: 28px;
  }

  .portal-card-title {
    font-size: 16px;
  }

  .portal-card-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .aboutus-root .col {
    width: 50% !important;
  }

  .aboutus-card-text {
    height: 130px !important;
  }

  .search_button {
    position: static !important;
    width: 60% !important;
    height: auto !important;
    margin: 10px auto !important;
  }

  .search_home_button {
    position: static !important;
    width: 50% !important;
    height: auto !important;
    margin: 10px auto !important;
    padding: 14px 0 !important;
  }

  .recent-upload-block {
    padding: 5px;
  }

  .profile-portal-block {
    margin: 30px 16px 80px;
  }

  body .portal-detail-tabs .nav-link {
    padding: 15px 20px 6px;
  }

  .fags-tabs {
    flex-direction: column;
  }

  .set-order-block {
    margin-bottom: 80px;
  }

  .align_right {
    text-align: left;
  }

  .navigations {
    width: 100%;
    display: block !important;
    text-align: center;
  }

  .navigation_button {
    width: auto !important;
    padding: 10px 20px !important;
    margin: 0px 15px 0px 0px !important;
  }

  .starta_home_search {
    width: 100% !important;
  }

  .starta_search {
    width: 100% !important;
  }

  select#select_strata_report {
    position: absolute;
    top: 6%;
    border: none;
    right: 4%;
    outline: none;
    color: grey;
    cursor: pointer;
    background: #fff;
    width: 92%;
    padding: 7px 18px;
  }
}

@media screen and (max-width: 480px) {
  .aboutus-root .my-2.row {
    display: block !important;
  }

  .aboutus-root .col {
    width: 100% !important;
  }

  .aboutus-card-text {
    height: auto !important;
  }
}

.select-search {
  width: 100%;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.srch_agent .select-search__value {
  position: unset;
  z-index: unset;
}

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

.agent_not_found {
  padding: 12px 0;
}

.agent_not_found input {
  margin-right: 12px;
  cursor: pointer;
}

.select-search__input {
  display: block;
  width: 100%;
  border: 0px !important;
  background: #ffffff;
  box-shadow: 5px 5px 15px rgb(67 127 236 / 10%);
  border-radius: 40px !important;
  font-family: Poppins;
  font-size: 15px !important;
  height: 48px !important;
  outline: none;
  -webkit-appearance: none;
  padding: 0 30px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 5px 5px 15px rgb(67 127 236 / 10%);
}

.select-search__options {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #e8f3ff;
  color: #000;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #e8f3ff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #0062cc;
  color: #fff;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

@media (max-width: 1366px) {
  .home-section-title {
    font-size: 41px;
    margin-bottom: 0;
  }

  .about-title-content {
    flex: 1;
    padding-left: 60px;
  }

  .home-section-title {
    width: unset;
  }
}

@media (max-width: 1199px) {
  .set-radio-mobile {
    flex-direction: column;
  }

  .app-text.strata-redio {
    margin-bottom: 10px;
  }

  .set-radio-mobile input {
    margin-top: 0px !important;
  }
}

@media (max-width: 991px) {
  .home-section-title {
    font-size: 31px;
  }

  .ourwork-card.card img {
    height: 300px !important;
  }
}

@media (max-width: 575px) {
  .home-section-title-1 {
    font-size: 15px;
    line-height: 28px;
  }

  .aboutus-card-title.card-title.h5 {
    font-size: 18px !important;
    line-height: 1.4 !important;
  }

  .home-section-title {
    font-size: 24px;
  }

  .ourwork-card.card img {
    height: 100% !important;
  }
}

@media (max-width: 350px) {
  .aboutus-card-title.card-title.h5 {
    height: unset;
  }
}

.activeCategory {
  color: #1d83ff !important;
}

.home-section-title {
  margin-bottom: 0 !important;
}

.h1_p,
.brand_p p {
  font-size: 20px;
  line-height: 1.5;
}

.brand_p h2 {
  font-family: Poppins_bold;
  font-weight: 500;
  font-size: 45px;
  line-height: 70px;
  color: #2e2e2e;
  line-height: 132.7%;
  width: 100%;
}

.brand_p p {
  font-family: Poppins;
  color: #757575;
}

.about-title-content {
  color: #757575;
}

.about-title-content.about-title-content1 {
  min-width: unset;
  max-width: unset;
  font-family: Poppins;
  color: #757575;
}

.custom_spacing {
  margin: 59px 0 36px;
}

@media (max-width: 575px) {
  .h1_p br {
    display: none;
  }

  .brand_p h2 {
    font-size: 24px;
  }

  .brand_p p {
    font-size: 15px;
  }
}

/* new module css */
.submenu {
  position: absolute;
  display: inline-block;
  background-color: white;
  transition: all 0.3s ease-out;
  z-index: 1;
  transform-origin: top;
  transform: scaleY(0);
  left: 267px;
  top: 20px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 12%), 0 0px 0px rgb(0 0 0 / 24%);
  border-radius: 5px;
}

.submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.submenu ul li {
  position: relative;
  padding: 0.5em;
  color: #444444 !important;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
}

.submenu ul li:hover {
  font-weight: 600;
  text-decoration: none;
  color: #000000 !important;
  background: transparent !important;
}

.valuation-submenu:hover {
  color: white;
  cursor: pointer;
}

.valuation-submenu:hover .submenu {
  transform: scale(1);
}

.valuation-submenu {
  position: relative;
}

.valuation-submenu a {
  display: flex;
  align-items: center;
}

.valuation-submenu i {
  margin-left: auto;
  font-size: 18px;
  font-weight: 400;
}

.thumb-bt {
  background: #1d83ff;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 21px;
  color: #fff;
  position: absolute;
  top: -1px;
  z-index: -1;
}

.fb-like {
  height: 32px;
  width: 38px;
  position: relative;
  z-index: 1;
  left: -15px;
  top: -1px;
  opacity: 0;
}

.blog-articles p.app-title {
  height: 48px;
  margin-bottom: 0 !important;
}

.blog-articles .card-body {
  padding: 10px !important;
}

.description {
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
}

.mobile-valuation li {
  list-style: none;
  font-size: 16px;
  line-height: 32px;
}

.mobile-valuation {
  padding-left: 10px;
}

.valuation-mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-angle-down {
  font-size: 22px;
}

.valuation-mob {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.navbar-desktop-link {
  margin-bottom: 0;
}

.footer-title {
  height: 30px;
}

.like-bt-box {
  position: absolute !important;
  top: 14px !important;
  left: 6px !important;
  z-index: 1 !important;
}

.like-bt-box #icon-button {
  height: 34px !important;
}

.react-multi-carousel-list {
  position: unset !important;
}

.strata-scroll {
  height: 190px;
  overflow: auto;
}

.radio-buttons .form-check {
  position: relative;
  display: inline-block;
  padding-left: 1.25rem;
}

.radio-buttons .form-check-label {
  margin-bottom: 0px;
  margin-right: 35px;
}

.fags-tabs .MuiTab-root {
  max-width: fit-content;
}

.image-left-box p {
  text-align: center !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.about-us-slider .react-multiple-carousel__arrow--left {
  left: calc(12% + 1px) !important;
}

.about-us-slider .react-multiple-carousel__arrow--right {
  right: calc(12% + 1px);
}

@media only screen and (max-width: 1750px) {
  .about-us-slider .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  }

  .about-us-slider .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px);
  }
}

@media only screen and (max-width: 1400px) {
  .about-us-slider .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
  }

  .about-us-slider .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
}